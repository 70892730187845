@import url(./styles/globals.css);

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: 'Gugi', cursive -apple-system,BlinkMacSystemFont,"Malgun Gothic","맑은 고딕",helvetica,"Apple SD Gothic Neo",sans-serif; */
  font-family: var(--mainFont), -apple-system, BlinkMacSystemFont, "Malgun Gothic", "맑은 고딕",
        helvetica, "Apple SD Gothic Neo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar{
  background: #e1e1e130;
  height:5px;
  width: 5px;
}

body::-webkit-scrollbar-track{
  border-radius: 10px;
}
body::-webkit-scrollbar-thumb{
  background-color: #00000030;
  border-radius: 10px;
}