@import url(./font-styles/Gugi.css);
@import url(./font-styles/NanumSquareNeo.css);
@import url(./font-styles/Pretendard.css);

:root{
    --mainColor: #b39283;
    --defaultBoxShadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);
    --defaultBoxShadow2: 4px 12px 30px 6px rgba(0,0,0,.09);
    --defaultBoxShadow2Hover:4px 12px 20px 6px rgba(0,0,0,.18);
    --defaultModalCloseColor: #959eae;
    /* --mainFont: 'NanumSquareNeo'; */
    --mainFont: 'Pretendard';
}